<template>
  <div>
    <HeaderMobile :links="links" v-if="isMobile" @logout="sair" />
    <HeaderDesktop :links="links" v-else @logout="sair" />
    <main class="pa-0 ma-0">
      <router-view />
    </main>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { logoutUser } from "@/services/login";
import HeaderMobile from "@/components/header/HeaderMobile.vue";
import HeaderDesktop from "@/components/header/HeaderDesktop.vue";
export default {
  name: "Default",
  components: {
    HeaderMobile,
    HeaderDesktop,
  },
  data: () => ({
    isMobile: false,
    links: [
      {
        title: "Solicitações",
        route: "",
        permission: "solicitacao.buscar",
        children: [
          {
            title: "Informações Cientificas",
            route: "Solicitacoes",
            permission: "solicitacao.buscar",
            excludeTypePermission: ["Colaborador", "Administrador de mesa"],
            mesaId: 1,
          },
          {
            title: "Treinamento",
            route: "SolicitacoesTreinamento",
            permission: "solicitacao.buscar",
            excludeTypePermission: ["Colaborador", "Administrador de mesa"],
            mesaId: 2,
          },
        ],
      },
      {
        title: "Banco de Modelos",
        route: "",
        permission: "carta.buscar",
        excludeTypePermission: "Representante",
        children: [
          {
            title: "Banco de Cartas (IC)",
            route: "BancoDeCartasIC",
            permission: "carta.buscar",
            excludeTypePermission: ["Colaborador", "Administrador de mesa"],
            mesaId: 1,
          },
          {
            title: "Banco de Cartas (TR)",
            route: "BancoDeCartasTR",
            permission: "carta.buscar",
            excludeTypePermission: ["Colaborador", "Administrador de mesa"],
            mesaId: 2,
          },
        ],
      },
      {
        title: "Médicos Solicitantes",
        route: "MedicosSolicitantes",
        permission: "medicoSolicitante.buscar",
        children: [],
      },
      {
        title: "Artigos",
        route: "Artigos",
        permission: "artigo.buscar",
        excludeTypePermission: "Representante",
        children: [],
      },
      {
        title: "Administração",
        route: "",
        permission: [
          "usuario.buscar",
          "empresaParceira.buscar",
          "produto.buscar",
          "linha.buscar",
        ],
        excludeTypePermission: "Representante",
        children: [
          {
            title: "Usuários",
            route: "Funcionarios",
            permission: "usuario.buscar",
          },
          {
            title: "Empresa Parceira",
            route: "EmpresasParceiras",
            permission: "empresaParceira.buscar",
          },
          {
            title: "Produtos",
            route: "Produtos",
            permission: "produto.buscar",
          },
          {
            title: "Linha de Produtos",
            route: "LinhasDeProdutos",
            permission: "linha.buscar",
          },
        ],
      },
      {
        title: "Configurações",
        route: "",
        permission: [
          "especialidadeMedica.buscar",
          "categoria.buscar",
          "tag.buscar",
          "config.editar",
        ],
        excludeTypePermission: "Representante",
        children: [
          {
            title: "Especialidades",
            route: "Especialidades",
            permission: "especialidadeMedica.buscar",
          },
          {
            title: "Categorias",
            route: "Categorias",
            permission: "categoria.buscar",
          },
          { title: "Tags", route: "Tags", permission: "tag.buscar" },
          {
            title: "Configurações Gerais",
            route: "Configuracoes",
            permission: "config.editar",
          },
        ],
      },
      {
        title: "Relatórios",
        route: "",
        permission: ["relatorio.dashboard"],
        excludeTypePermission: "",
        children: [
          {
            title: "Dashboard",
            route: "RelatorioDashboard",
            permission: "relatorio.dashboard",
          },
          {
            title: "Exportar Solicitações",
            route: "RelatorioExportarSolicitacoes",
            permission: "relatorio.dashboard",
          },
          {
            title: "Atendimentos pelo Médico Aprovador",
            route: "RelatorioAtendimentosMedicoAprovador",
            permission: "relatorio.dashboard",
          },
          {
            title: "Atendimentos pelo Colaborador",
            route: "RelatorioAtendimentosColaborador",
            permission: "relatorio.dashboard",
          },
          {
            title: "Solicitações Por Produto",
            route: "RelatorioSolicitacoesPorProduto",
            permission: "relatorio.dashboard",
          },
          {
            title: "Solicitações Por Linha",
            route: "RelatorioSolicitacoesPorLinha",
            permission: "relatorio.dashboard",
          },
        ],
      },
    ],
  }),
  created() {
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth <= 1100;
    });
    this.isMobile = window.innerWidth <= 1100;
  },
  methods: {
    async sair() {
      try {
        await logoutUser()
          .then(() => {
            this.$store.dispatch("setUserLoggedIn", false);
            this.$store.dispatch("setUserData", []);
            Cookies.remove("loggedIn");
            Cookies.remove("userData");
            window.localStorage.removeItem("token");
          })
          .then(() => {
            window.location = process.env.VUE_APP_LOGIN;
          });
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
